      var hasPanel = $('.has-panel');
      var panelIcon = $('.panel-icon');
      var togglePanel = $('.toggle-panel');
      var panelTitle = $('.panel-title');
      
      hasPanel.click(function (event) {
        event.preventDefault();
        if ($(togglePanel).is(':visible')) {
          $(togglePanel).slideUp(300, 'linear');
          $(togglePanel).removeClass('toggle-panel--active');
          $(panelIcon).removeClass('panel-icon--minus');
          $(panelIcon).addClass('panel-icon--plus');
          $(panelTitle).removeClass('panel-title--active');
        }
        if ($(this).next(togglePanel).is(':visible')) {
          $(this).next(togglePanel).slideUp(300, 'linear');
          $(this).children(panelIcon).addClass('panel-icon--plus');
        } else {
          $(this).next(togglePanel).slideDown(300, 'linear');
          $(this).next(togglePanel).addClass('toggle-panel--active');
          $(this).children(panelIcon).addClass('panel-icon--minus');
          $(this).children(panelTitle).addClass('panel-title--active');
        }
      });
      
      var $header = $('.header');
      var $headerLg = $('.header--lg');

      // Counter above the fold
      if ($headerLg.next('.counter').length) {
        $headerLg.addClass('fold');
      }
      
      // Scroll down
      var scrollDown = $('.scroll-down');

      $(scrollDown).click(function () {
        $('html, body').animate({
          scrollTop: $(this).offset().top
        }, $header.innerHeight());
      });
      
      
      var slider = new Swiper('.slider', {

        autoplay: {
          delay: 5000,
        },

        speed: 800,
        loop: true,

        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        
      })
      
      if ($('.slider--fw .swiper-slide').length > 1) {
        var sliderFw = new Swiper('.slider--fw', {

          autoplay: {
            delay: 5000,
          },

          speed: 800,
          loop: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>';
            },
          },
          
        })

        $('.slider--fw .container').hover(function () {
          sliderFw.autoplay.stop();
        }, function () {
          sliderFw.autoplay.start();
        });
      }
      
      $(document).bind('keydown', function (event) {
        if (event.which == 27) {
          $('#main-menu').removeClass('is-active');
        }
      });

      $('.menu-toggler').click(function (event) {
        event.preventDefault();
        $('#main-menu').toggleClass('is-active');
      });
      
      
      $('.selected').click(function (event) {
        event.preventDefault();
        // $('.options ul').css("display", "block");
         $('.options ul').toggle(300);
      });
      
      $('.tabtitle').click(function (event) {
        event.preventDefault();
        if ($(this).hasClass('active') == false) {
         $(this).closest('.tabtitles').find('.tabtitle').toggleClass('active');
         $(document).find('.hometab').toggleClass('active');
        }
      });
      
      function setMarginItems() {
        $( ".inMargin div" ).each(function( index, marginItem ) {
          var anchor = $( "#psideId"+ (index + 1));
          var offset = $(anchor).parents('table').length > 0 ? 12 : -1;
           $(marginItem).offset({ top: anchor.offset().top - offset });
        });
      }
      
      $(document).ready(function() {
          setMarginItems();
      });
      $(window).on('load', function() {
          setMarginItems();
      });
      
      $(window).resize(function(){
        setMarginItems();
       });

      $(window).scroll(function() {
        /* if ($(togglePanel).is(':visible')) {
          $(togglePanel).slideUp(300, 'linear');
          $(togglePanel).removeClass('toggle-panel--active');
          $(panelIcon).removeClass('panel-icon--minus');
          $(panelIcon).addClass('panel-icon--plus');
          $(panelTitle).removeClass('panel-title--active')
        }*/
        
        var scrollTop = $(window).scrollTop();
        var headerTop = $(".header__top").innerHeight();

        if (scrollTop > headerTop) {
           // Drupal.behaviors.shvNavigation.menu.addClass("inverse");
            //Drupal.behaviors.shvNavigation.header.addClass("fixed");
            
            $("#main-menu").addClass("inverse");
            $(".header.header--lg").addClass("fixed");
            $(".header.header--sm").addClass("fixed");
            
            $(".header__top").addClass("minimize");
            $(".menu__slide").addClass("minimize");
        } else {
           // Drupal.behaviors.shvNavigation.menu.removeClass("inverse");
           // Drupal.behaviors.shvNavigation.header.removeClass("fixed");
           
            $("#main-menu").removeClass("inverse");
            $(".header.header--lg").removeClass("fixed");
            $(".header.header--sm").removeClass("fixed");

           $(".header__top").removeClass("minimize");
           $(".menu__slide").removeClass("minimize");
        }
            
      });
      
      $(function () {
        $('#vidBox').VideoPopUp({
          opener: "video-trigger",
          idvideo: "demo",
          backgroundColor: "rgba(0,0,0,.75)",
          pausevideo: true
        });
      });
      
      $(".video-trigger").click(function (event) {
        $("#video-trigger").click();
      });

// check Mac for red triangle css bug
	
  var isMac = 0;
  var isWin = 0; 
  var isOtherOS = 0; 
  var isUndetected = 0; 
  var agent = navigator.platform.toLowerCase();
  
  if (agent.indexOf('mac') != -1) isMac = 1; 
  else if (agent.indexOf('win') != -1) isWin = 1; 
  else if ( agent.indexOf('unix') != -1 ||      
            agent.indexOf('sunos') != -1 ||      
            agent.indexOf('bsd') != -1 ||      
            agent.indexOf('x11') != -1 ||      
            agent.indexOf('linux') != -1) isOtherOS = 1; 
  else ( isUndetected = 1 );
  
  /* if (isMac) { alert('This Browser is running in the Mac OS.'); } 
  else if (isWin) { alert('This Browser is running in the Microsoft Windows OS.'); } 
  else if (isOtherOS) { alert('RESISTANCE IS FUTILE...YOU WILL BE ASSIMULATED'); } 
  else { document.write('Sorry, I\'m not sure what OS you are using.'); }
  */
  
  if (isMac) {
    $('body').addClass('mac');
  }

$(".vpop").on('click', function(e) {
  e.preventDefault();
  $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();
  
  var srchref='',autoplay='',id=$(this).data('id');
  if($(this).data('type') == 'vimeo') var srchref="//player.vimeo.com/video/";
  else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";
  
  if($(this).data('autoplay') == true) autoplay = '?autoplay=1';
  
  $("#video-popup-iframe").attr('src', srchref+id+autoplay);
  
  $("#video-popup-iframe").on('load', function() {
    $("#video-popup-container").show();
  });
});

$("#video-popup-close, #video-popup-overlay").on('click', function(e) {
  $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
  $("#video-popup-iframe").attr('src', '');
});